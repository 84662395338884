import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import NavLinks from '../components/nav-links'
import Testimonial from '../components/testimonial'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>VoxVerse</title>
        <meta property="og:title" content="VoxVerse" />
      </Helmet>
      <header className="home-header1">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <div className="home-branding1">
            <span className="home-text100">VoxVerse</span>
          </div>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="home-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="home-nav1"
            >
              <div className="home-right">
                <NavLinks></NavLinks>
                <div className="home-button1">
                  <button className="home-work-with-us1 button">
                    <span className="home-text101">work with us</span>
                    <img
                      alt="image"
                      src="/hamburger.svg"
                      className="home-image10"
                    />
                  </button>
                </div>
              </div>
            </nav>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <button className="home-work-with-us2 button">
              <span className="home-text102">work with us</span>
              <img alt="image" src="/hamburger.svg" className="home-image11" />
            </button>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav2"
            >
              <div className="home-top">
                <div className="home-branding2">
                  <span className="home-text103">VoxVerse</span>
                </div>
                <div data-thq="thq-close-menu" className="home-menu-close">
                  <svg viewBox="0 0 1024 1024" className="home-icon1">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="home-middle"
              >
                <div className="home-links">
                  <div className="nav-links-links">
                    <span className="navLink">Products</span>
                    <span className="navLink">Blog</span>
                    <span className="navLink">Contact</span>
                  </div>  
                </div>
                <button className="home-work-with-us3 button">
                  <span className="home-text104">Work with us</span>
                </button>
              </nav>
            </div>
          </div>
        </header>
      </header>
      <div className="home-hero">
        <div className="home-header2">
          <h1 className="home-text105">
            AI <em>for</em> Humans. Not against.
          </h1>
          {/* <img alt="image" src="/mouse.svg" className="home-image12" /> */}
        </div>
        <div className="home-hero-image">
          <img alt="image" src="/computer.svg" className="home-image13" />
        </div>
      </div>
      <div className="home-who">
        <div className="home-header3">
          <div className="home-heading1">
            <h2 className="home-text106">
              We are an Indian company whose products are built on top of fundamental 
              research outcomes, 100% homegrown.
            </h2>
            <span className="home-text107">
              In an AI driven world, we believe humans will become even more important. Not less.
              We are working hard to drive real outcomes for over half of humanity.
            </span>
          </div>
          {/* <button className="home-button2 button">let’s chat</button> */}
        </div>
        <div className="home-preview">
          <div className="home-image14">
            <img alt="image" src="/preview.svg" className="home-image15" />
          </div>
          <div className="home-video1">
            <video src poster="/video.svg" className="home-video2"></video>
          </div>
        </div>
      </div>
      <div className="home-information">
        <div className="home-purpose">
          <span className="home-caption1">What we do</span>
          <span className="home-description1">
            VoxVerse is building real-time AI technologies that make humans
            20x more productive. Artificial bottlenecks in education, vocational training,
            marketing and sales will now become a thing of the past. We are systematically
            breaking down communication barriers, cultural differences and privileged access 
            to information. 
          </span>
        </div>
        <div className="home-industries">
          <span className="home-caption2">INDUSTRIES</span>
          <div className="home-list">
            <div data-role="accordion-container" className="home-accordion">
              <div data-role="accordion-trigger" className="accordion-trigger">
                <span className="home-title10">Education</span>
              </div>
              {/* <div data-role="accordion-content" className="accordion-content">
                <span className="home-text108">Perspiciatis</span>
                <span className="home-text109">Nemo voluptatem</span>
                <span className="home-text110">Dolore magnam</span>
                <span className="home-text111">Tempora</span>
                <span className="home-text112">Reprehenderit qui</span>
              </div> */}
            </div>
            <div data-role="accordion-container" className="home-item1">
              <div data-role="accordion-trigger" className="accordion-trigger">
                <span className="home-title11">HR</span>
              </div>
              {/* <div data-role="accordion-content" className="accordion-content">
                <span className="home-text113">Perspiciatis</span>
                <span className="home-text114">Nemo voluptatem</span>
                <span className="home-text115">Dolore magnam</span>
                <span className="home-text116">Tempora</span>
                <span className="home-text117">Reprehenderit qui</span>
              </div> */}
            </div>
            <div data-role="accordion-container" className="home-item2">
              <div
                data-role="accordion-trigger"
                // className="home-trigger3 accordion-trigger accordion-trigger-active"
                className="home-trigger3 accordion-trigger"
              >
                <span className="home-title12">Communications</span>
              </div>
              {/* <div
                data-role="accordion-content"
                className="accordion-content accordion-content-active"
              >
                <span className="home-text118">Perspiciatis</span>
                <span className="home-text119">Nemo voluptatem</span>
                <span className="home-text120">Dolore magnam</span>
                <span className="home-text121">Tempora</span>
                <span className="home-text122">Reprehenderit qui</span>
              </div> */}
            </div>
            <div data-role="accordion-container" className="home-item3">
              <div
                data-role="accordion-trigger"
                className="home-trigger4 accordion-trigger"
              >
                <span className="home-title13">Ecommerce</span>
              </div>
              {/* <div data-role="accordion-content" className="accordion-content">
                <span className="home-text123">Perspiciatis</span>
                <span className="home-text124">Nemo voluptatem</span>
                <span className="home-text125">Dolore magnam</span>
                <span className="home-text126">Tempora</span>
                <span className="home-text127">Reprehenderit qui</span>
              </div> */}
            </div>
            <div data-role="accordion-container" className="home-item4">
              <div
                data-role="accordion-trigger"
                className="home-trigger5 accordion-trigger"
              >
                <span className="home-title14">Marketing</span>
              </div>
              {/* <div data-role="accordion-content" className="accordion-content">
                <span className="home-text128">Perspiciatis</span>
                <span className="home-text129">Nemo voluptatem</span>
                <span className="home-text130">Dolore magnam</span>
                <span className="home-text131">Tempora</span>
                <span className="home-text132">Reprehenderit qui</span>
              </div> */}
            </div>
            <div data-role="accordion-container" className="home-item5">
              <div
                data-role="accordion-trigger"
                className="home-trigger6 accordion-trigger"
              >
                <span className="home-title15">SaaS</span>
              </div>
              {/* <div data-role="accordion-content" className="accordion-content">
                <span className="home-text133">Perspiciatis</span>
                <span className="home-text134">Nemo voluptatem</span>
                <span className="home-text135">Dolore magnam</span>
                <span className="home-text136">Tempora</span>
                <span className="home-text137">Reprehenderit qui</span>
              </div> */}
            </div>
            <div data-role="accordion-container" className="home-item6">
              <div
                data-role="accordion-trigger"
                className="home-trigger7 accordion-trigger"
              >
                <span className="home-title16">Media</span>
              </div>
              {/* <div data-role="accordion-content" className="accordion-content">
                <span className="home-text138">Perspiciatis</span>
                <span className="home-text139">Nemo voluptatem</span>
                <span className="home-text140">Dolore magnam</span>
                <span className="home-text141">Tempora</span>
                <span className="home-text142">Reprehenderit qui</span>
              </div> */}
            </div>
            <div data-role="accordion-container" className="home-item7">
              <div
                data-role="accordion-trigger"
                className="home-trigger8 accordion-trigger"
              >
                <span className="home-title17">Social</span>
              </div>
              {/* <div data-role="accordion-content" className="accordion-content">
                <span className="home-text143">Perspiciatis</span>
                <span className="home-text144">Nemo voluptatem</span>
                <span className="home-text145">Dolore magnam</span>
                <span className="home-text146">Tempora</span>
                <span className="home-text147">Reprehenderit qui</span>
              </div> */}
            </div>
          </div>
          <div>
            <div className="home-container3">
              <Script
                html={`<script>
      /*
  Accordion - Code Embed
  */

  /* listenForUrlChangesAccordion() makes sure that if you changes pages inside your app,
  the Accordions will still work*/

  const listenForUrlChangesAccordion = () => {
        let url = location.href;
        document.body.addEventListener(
          "click",
          () => {
            requestAnimationFrame(() => {
              if (url !== location.href) {
                runAccordionCodeEmbed();
                url = location.href;
              }
            });
          },
          true
        );
      };


  const runAccordionCodeEmbed = () => {
      const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
      const allTriggers = document.querySelectorAll('[data-role="accordion-trigger"]'); // All accordion triggers
      const allContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content

      accordionContainers.forEach((container) => {
        const accordionTrigger = container.querySelector('[data-role="accordion-trigger"]'); // Scopped accordion trigger
        const accordionContent = container.querySelector('[data-role="accordion-content"]'); // Scopped accordion content

        accordionTrigger.addEventListener("click", ()=>{
          /*
          Uncomment the code bellow to make all the other sections
          collapse when clicking on one of them.

          allTriggers.forEach(trigger => trigger.classList.remove("accordion-trigger-active"))
          allContents.forEach(content => content.classList.remove("accordion-content-active"))
          */
          if(accordionContent.classList.contains("accordion-content-active")){
            accordionContent.classList.remove("accordion-content-active");
            accordionTrigger.classList.remove("accordion-trigger-active")
          } else {
            accordionContent.classList.add("accordion-content-active");
            accordionTrigger.classList.add("accordion-trigger-active")
          }
        })

      });
  }

  runAccordionCodeEmbed()
  listenForUrlChangesAccordion()

  /*
  Here's what the above is doing:
      1. Selects all accordion containers, contents, and icons
      2. Hides all accordion contents
      3. Adds an event listener to each accordion container
      4. When an accordion container is clicked, it:
          - Hides all accordion contents
          - Resets all icon transforms to 0deg (default)
          - Checks if this container has class "accordion-open"
              - If it does, it removes class "accordion-open"
              - If it doesn't, it:
                  - Removes class "accordion-open" from all containers
                  - Adds class "accordion-open" to this container
                  - Shows accordion content
                  - Rotates accordion icon 180deg
  */
</script>
`}
              ></Script>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="home-section1">
        <div className="home-case-studies">
          <div className="home-caption3">
            <span className="home-text148">Case Studies</span>
            <div className="home-date-range">
              <span className="home-text149">2022</span>
              <span className="home-text150">——</span>
              <span className="home-text151">2024</span>
            </div>
          </div>
          <div className="home-gallery1">
            <div className="home-row1">
              <div className="home-case1">
                <img
                  alt="image"
                  src="/study%20%231-1300w.png"
                  className="home-image16"
                />
                <div className="home-heading2">
                  <span className="home-text152">Perspiciatis</span>
                  <span className="home-text153">
                    Lorem ipsum dolor sit amet consectetur adipiscing.
                  </span>
                </div>
              </div>
              <div className="home-case2">
                <img
                  alt="image"
                  src="/study%20%232-1400w.png"
                  className="home-image17"
                />
                <div className="home-heading3">
                  <span className="home-text154">Nemo voluptatem</span>
                  <span className="home-text155">
                    Lorem ipsum dolor sit amet consectetur adipiscing.
                  </span>
                </div>
              </div>
            </div>
            <div className="home-row2">
              <div className="home-case3">
                <img
                  alt="image"
                  src="/study%20%233-1400w.png"
                  className="home-image18"
                />
                <div className="home-heading4">
                  <span className="home-text156">Dolore magnam</span>
                  <span className="home-text157">
                    Lorem ipsum dolor sit amet consectetur adipiscing.
                  </span>
                </div>
              </div>
              <div className="home-case4">
                <img
                  alt="image"
                  src="/study%20%234-1400w.png"
                  className="home-image19"
                />
                <div className="home-heading5">
                  <span className="home-text158">Tempora</span>
                  <span className="home-text159">
                    Lorem ipsum dolor sit amet consectetur adipiscing.
                  </span>
                </div>
              </div>
            </div>
            <div className="home-row3">
              <div className="home-case5">
                <img
                  alt="image"
                  src="/study%20%235-1300w.png"
                  className="home-image20"
                />
                <div className="home-heading6">
                  <span className="home-text160">Reprehenderit qui</span>
                  <span className="home-text161">
                    Lorem ipsum dolor sit amet consectetur adipiscing.
                  </span>
                </div>
              </div>
              <div className="home-case6">
                <img
                  alt="image"
                  src="/study%20%236-1300w.png"
                  className="home-image21"
                />
                <div className="home-heading7">
                  <span className="home-text162">Ullamco laboris</span>
                  <span className="home-text163">
                    Lorem ipsum dolor sit amet consectetur adipiscing.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-testimonials">
          <div className="home-header4">
            <span className="home-caption4">What our clients say</span>
            <div className="home-controls">
              <svg
                id="quote-previous"
                viewBox="0 0 1024 1024"
                className="home-icon3"
              >
                <path d="M670.165 737.835l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-256 256c-16.683 16.683-16.683 43.691 0 60.331l256 256c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
              </svg>
              <span className="home-text164">——</span>
              <svg
                id="quote-next"
                viewBox="0 0 1024 1024"
                className="home-icon5"
              >
                <path d="M414.165 798.165l256-256c16.683-16.683 16.683-43.691 0-60.331l-256-256c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0z"></path>
              </svg>
            </div>
          </div>
          <div className="home-row4">
            <div className="quote">
              <Testimonial rootClassName="testimonialroot-class-name"></Testimonial>
            </div>
            <div className="home-quote2 quote">
              <Testimonial
                name="Coco White"
                avatar="https://images.unsplash.com/photo-1618151313441-bc79b11e5090?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxwb3RyYWl0fGVufDB8fHx8MTY2OTcyODk4OA&amp;ixlib=rb-4.0.3&amp;h=200"
                origin="@white_co"
                rootClassName="testimonialroot-class-name1"
              ></Testimonial>
            </div>
            <div className="quote">
              <Testimonial
                avatar="https://images.unsplash.com/photo-1610276198568-eb6d0ff53e48?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBvdHJhaXR8ZW58MHx8fHwxNjY5NzI4OTg4&amp;ixlib=rb-4.0.3&amp;h=200"
                rootClassName="testimonialroot-class-name2"
              ></Testimonial>
            </div>
            <div className="home-gradiant"></div>
          </div>
        </div>
      </div> */}
      <div className="home-book">
        <div className="home-content2">
          <div className="home-left">
            <h2 className="home-text165">
              Book a 15 minutes consultation to check how can we help you
            </h2>
            <button className="home-button3 button">
              <span className="home-text166">book a call</span>
            </button>
          </div>
          <img alt="image" src="/message.svg" className="home-image22" />
        </div>
      </div>
      {/* <div className="home-section2">
        <div className="home-about-us">
          <div className="home-header5">
            <span className="home-caption5">About us</span>
            <h2 className="home-description2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor.
            </h2>
          </div>
          <div className="home-statistics">
            <div className="home-statistic1">
              <span className="home-text167">25</span>
              <span className="home-text168">Years of experience</span>
            </div>
            <div className="home-statistic2">
              <span className="home-text169">40+</span>
              <span className="home-text170">Years of Experts on board</span>
            </div>
            <div className="home-statistic3">
              <span className="home-text171">300+</span>
              <span className="home-text172">Finished projects</span>
            </div>
            <div className="home-statistic4">
              <span className="home-text173">3</span>
              <span className="home-text174">Offices worldwide</span>
            </div>
          </div>
          <div className="home-dna">
            <span className="home-caption6">Our DNA</span>
            <span className="home-description3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </span>
            <span className="home-description4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </span>
          </div>
          <div className="home-gallery2">
            <img
              alt="image"
              src="/linkedin-sales-solutions-hrhjn6ztgrm-unsplash%201-700w.png"
              className="home-image23"
            />
            <img
              alt="image"
              src="/austin-distel-800w.png"
              className="home-image24"
            />
          </div>
          <div className="home-recruit">
            <h2 className="home-text175">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor.
            </h2>
            <div className="home-sections">
              <div className="home-section3">
                <span className="home-text176">lumenco laboris</span>
                <span className="home-text177">
                  Minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat
                </span>
              </div>
              <div className="home-section4">
                <span className="home-text178">Adipiscing elit</span>
                <span className="home-text179">
                  Minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat
                </span>
              </div>
              <div className="home-section5">
                <span className="home-text180">Dolor sit</span>
                <span className="home-text181">
                  Minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat
                </span>
              </div>
              <div className="home-section6">
                <span className="home-text182">Huiposcing</span>
                <span className="home-text183">
                  Minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat
                </span>
              </div>
            </div>
            <button className="home-button4 button">
              <span className="home-text184">
                <span>be part of our team</span>
                <br></br>
              </span>
            </button>
          </div>
        </div>
      </div> */}
      <footer className="home-footer">
        <div className="home-header6">
          <span className="home-branding3">VoxVerse</span>
          <div className="home-socials1">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link13"
            >
              <img
                alt="image"
                src="/facebook%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link14"
            >
              <img
                alt="image"
                src="/twitter%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://dribbble.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link15"
            >
              <img
                alt="image"
                src="/dribbble%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link16"
            >
              <img
                alt="image"
                src="/instagram%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link17"
            >
              <img
                alt="image"
                src="/linkedin%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://youtube.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link18"
            >
              <img
                alt="image"
                src="/youtube%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://telegram.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link19"
            >
              <img
                alt="image"
                src="/telegram%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://medium.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link20"
            >
              <img
                alt="image"
                src="/medium%20-%20negative.svg"
                className="social"
              />
            </a>
          </div>
        </div>
        <div className="home-content3">
          <div className="home-contact">
            <span className="home-email">admin@voxverse.ai</span>
            {/* <span className="home-number">+91 222.345.987</span> */}
          </div>
          <div className="home-links-row">
            {/* <div className="home-links-column1">
              <span className="home-link21">Responsive Web Design</span>
              <span className="home-link22">Design to Code</span>
              <span className="home-link23">Static Website Designer</span>
              <span className="home-link24">Static Website Generator</span>
            </div> */}
            <div className="home-links-column2">
              <span className="home-link25">About</span>
              <span className="home-link26">Team</span>
              <span className="home-link27">News</span>
              <span className="home-link28">Partners</span>
              <span className="home-link29">Careers</span>
              <span className="home-link30">Press &amp; Media</span>
            </div>
          </div>
        </div>
        <div className="home-locations">
          <div className="home-location1">
            <span className="home-title18">India</span>
            <span className="home-details17">
              <span>Plot no.5, Doctor's colony, Inorbit Mall Rd</span>
              <br></br>
              <span>Hyderabad, Telangana, India</span>
              <br></br>
              <br></br>
              <span>admin@voxverse.ai</span>
            </span>
          </div>
          {/* <div className="home-location2">
            <span className="home-title19">Romania</span>
            <span className="home-details18">
              <span>115 Turzii Road</span>
              <br></br>
              <span>Cluj Napoca</span>
              <br></br>
              <br></br>
              <span>hola_ro@hola.studio</span>
            </span>
          </div>
          <div className="home-location3">
            <span className="home-title20">United Kingdom</span>
            <span className="home-details19">
              <span>87–135 Brompton Road</span>
              <br></br>
              <span>London</span>
              <br></br>
              <br></br>
              <span>hola_uk@hola.studio</span>
            </span>
          </div>
          <div className="home-location4">
            <span className="home-title21">Spain</span>
            <span className="home-details20">
              <span>34-36 Carrer de Tele</span>
              <br></br>
              <span>Barcelona</span>
              <br></br>
              <br></br>
              <span>hola_sp@hola.studio</span>
            </span>
          </div> */}
        </div>
        <div className="home-socials2">
          <div className="home-row5">
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/facebook%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/twitter%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/dribbble%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/instagram%20-%20negative.svg"
                className="social"
              />
            </a>
          </div>
          <div className="home-row6">
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/linkedin%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/youtube%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/telegram%20-%20negative.svg"
                className="social"
              />
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/medium%20-%20negative.svg"
                className="social"
              />
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home
